import { ErrorHandler, ERROR_CATCH_TYPE } from "@classdojo/web/pods/errorHandling";
import { UserActionsHistory } from "@classdojo/web/pods/userActionsHistory";
import { reset as resetSagaCounter } from "@web-monorepo/infra/createWaitableSaga";
import { flatten } from "@web-monorepo/shared/podInfra";
import { compose, createStore, combineReducers, applyMiddleware } from "redux";
import type { Store as ReduxStore, Middleware } from "redux";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import testingLoggerMiddleware from "app/middleware/testLogger";
import allPods from "app/pods/allPods";
import env from "app/utils/env";

/* eslint-disable @typescript-eslint/no-namespace, no-var */
declare global {
  namespace NodeJS {
    interface Global {
      Store: ReduxStore;
      hideLogs: boolean;
      shouldLog: boolean;
    }
  }
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: () => VoidFunction;
  }
  namespace globalThis {
    var Store: ReduxStore;
    var hideLogs: boolean;
    var shouldLog: boolean;
  }
}
/* eslint-enable @typescript-eslint/no-namespace, no-var */

const enhancers = [
  env.isDev && window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : null,
].filter((enhancer): enhancer is VoidFunction => !!enhancer);

let _store: ReduxStore;

const makeStore = (errorHandler: ErrorHandler, userActionsHistory: UserActionsHistory) => {
  const { reducerMap, sagas, middleware: podMiddleware } = flatten(allPods);
  const combinedReducers = combineReducers({
    ...reducerMap,
  });

  let isDead = false;
  const teardownMiddleware: Middleware = () => (next) => (action) => {
    if (!isDead) {
      return next(action);
    }
  };

  const sagaMiddleware = createSagaMiddleware();

  const middleware = [
    teardownMiddleware,
    errorHandler.reduxMiddleware,
    sagaMiddleware,
    userActionsHistory.reduxMiddleware,
    env.isTesting
      ? testingLoggerMiddleware
      : createLogger({
          predicate: () => (env.isDev && !global.hideLogs && !window.__REDUX_DEVTOOLS_EXTENSION__) || global.shouldLog,
        }),
    ...podMiddleware,
  ].filter(Boolean);

  const createStoreWithMiddleware = compose(applyMiddleware(...middleware), ...enhancers)(createStore);

  _store = createStoreWithMiddleware(combinedReducers);

  const sagaTasks = sagas.map((saga) => {
    const task = sagaMiddleware.run(saga);
    task.toPromise().catch((e: any) => {
      errorHandler.handleError(e, { catchType: ERROR_CATCH_TYPE.SAGA_TASK });
    });
    return task;
  });

  if (!env.isProd) {
    global.Store = _store;
  }

  return {
    store: _store,
    teardown: () => {
      isDead = true;

      // stop all currently running sagas
      sagaTasks.forEach((task) => task.cancel());
      resetSagaCounter();
    },
  };
};

export default makeStore;

export function getStore() {
  return _store;
}
