import { UploadType } from "@classdojo/web/utils/uppy/uploadConfigs";
import callApi from "@web-monorepo/infra/callApi";
import { makeCollectionQuery, makeMutation } from "@web-monorepo/shared/reactQuery";
import { RenderedLike } from "./types";
import { useSessionFetcher } from "app/pods/auth";
import { PortfolioAttachment } from "app/pods/drafts/types";

export const usePortfolioCommentsFetcher = makeCollectionQuery({
  path: "/api/dojoClass/{classId}/portfolio/{postId}/comments",
  fetcherName: "portfolioItemComments",
});

interface SubmitPortfolioCommentOperationParams {
  classroomId?: string;
  postId?: string;
  body: string;
}

export const useSubmitPortfolioCommentOperation = makeMutation<SubmitPortfolioCommentOperationParams, void>({
  name: "submitCommentDraft",
  fn: async ({ classroomId, postId, body }) => {
    await callApi({
      method: "POST",
      path: `/api/dojoClass/${classroomId}/portfolio/${postId}/comments`,
      body: { body },
    });
  },
  onSuccess: () => {
    usePortfolioCommentsFetcher.invalidateQueries();
  },
});

interface DeletePortfolioCommentOperationParams {
  classroomId?: string;
  postId?: string;
  commentId: string;
}

export const useDeletePortfolioCommentOperation = makeMutation<DeletePortfolioCommentOperationParams, void>({
  name: "deleteCommentDraft",
  fn: async ({ classroomId, postId, commentId }) => {
    await callApi({
      path: `/api/dojoClass/${classroomId}/portfolio/${postId}/comments/${commentId}`,
      method: "DELETE",
    });
  },
  onSuccess: () => {
    usePortfolioCommentsFetcher.invalidateQueries();
  },
});

export const usePortfolioItemsFetcher = makeCollectionQuery({
  fetcherName: "portfolioItems",
  path: "/api/portfolio",
  query: { limit: "28", includeMultipageWorksheets: "true" },
});

export const usePortfolioItemViewsFetcher = makeCollectionQuery({
  fetcherName: "portfolioItemViews",
  dontThrowOnStatusCodes: [404],
  path: "/api/dojoClass/{targetId}/storyPost/{postId}/views",
});

interface LikeAndUnlikePortfolioItemOperationParams {
  classroomId: string;
  itemId: string;
}

export const useLikePortfolioItemOperation = makeMutation<LikeAndUnlikePortfolioItemOperationParams, void>({
  name: "likePortfolioItem",
  async fn({ classroomId, itemId }) {
    await callApi({
      method: "POST",
      path: "/api/storyBatchAction?withStudentCommentsAndLikes=true",
      body: {
        actions: [{ action: "like", targetType: "class", targetId: classroomId, postId: itemId }],
      },
    });
  },
  onMutate: (params) => {
    const sessionData = useSessionFetcher.getQueryData();
    const studentId = sessionData?.student?._id;

    usePortfolioItemLikesFetcher.setQueriesData(
      (draft) => {
        draft?.push({ type: "student", _id: studentId!, likedAt: new Date().toISOString() });
      },
      { postId: params.itemId, classId: params.classroomId },
    );
  },
});

export const useUnlikePortfolioItemOperation = makeMutation<LikeAndUnlikePortfolioItemOperationParams, void>({
  name: "unlikePortfolioItem",
  async fn({ classroomId, itemId }) {
    await callApi({
      method: "POST",
      path: "/api/storyBatchAction?withStudentCommentsAndLikes=true",
      body: {
        actions: [{ action: "unlike", targetType: "class", targetId: classroomId, postId: itemId }],
      },
    });
  },
  onMutate: (params) => {
    const sessionData = useSessionFetcher.getQueryData();
    const studentId = sessionData?.student?._id;

    usePortfolioItemLikesFetcher.setQueriesData(
      (draft) => {
        return draft?.filter((item) => item._id !== studentId);
      },
      { postId: params.itemId, classId: params.classroomId },
    );
  },
});

interface PortfolioItemLikesFetcherParams {
  classId: string;
  postId: string;
  studentId: string;
}

export const usePortfolioItemLikesFetcher = makeCollectionQuery<
  "/api/dojoClass/{classId}/storyPost/{postId}/likes",
  never,
  RenderedLike,
  PortfolioItemLikesFetcherParams
>({
  fetcherName: "portfolioItemLikes",
  dontThrowOnStatusCodes: [404, 403],
  path: "/api/dojoClass/{classId}/storyPost/{postId}/likes",
  query: { withStudentCommentsAndLikes: "true" },
});

export interface StoryAttachment {
  type: UploadType;
  path: string;
  metadata?: {
    contentType?: string;
    width?: number;
    height?: number;
  };
}

export function formatStoryAttachmentForPortfolio(
  storyAttachment: StoryAttachment /*, mimetype*/,
): PortfolioAttachment {
  return {
    type: storyAttachment.type,
    path: storyAttachment.path,
    contentType: storyAttachment.metadata?.contentType,
    dimensions: {
      width: storyAttachment.metadata?.width || 0,
      height: storyAttachment.metadata?.height || 0,
    },
    metadata: {
      ...storyAttachment.metadata,
      // NOTE: not sending mimetype for now because API isn't handling it
      // correctly when transcoding. It leaves mimetype in metadata instead of
      // writing the transcoded mimetype
      // mimetype,
    },
  };
}
