import { components } from "@classdojo/ts-api-types/api";
import callApi from "@web-monorepo/infra/callApi";
import { APIRequestBody, APIResponse } from "@web-monorepo/shared/api/apiTypesHelper";
import { DojoError } from "@web-monorepo/shared/errors/errorTypeMaker";
import { makeApiMutation, makeMemberQuery, makeMutation } from "@web-monorepo/shared/reactQuery";
import errors from "app/errors";
import { useStudentsFetcher } from "app/pods/student";
import { getLocalTokenData } from "app/pods/tokenLogin";
import matchesError from "app/utils/matchesError";
import { useCallback } from "react";

export const ACCOUNT_SWITCHER_KEY = "accountSwitcher";

type ForgotPasswordParams = APIRequestBody<"/api/studentForgotPassword", "post">;
type ForgotPasswordResponse = APIResponse<"/api/studentForgotPassword", "post">;

export const useForgotPasswordOperation = makeMutation<
  ForgotPasswordParams,
  { body: ForgotPasswordResponse },
  DojoError
>({
  name: "forgotPasswordOperation",
  fn: async ({ username }) => {
    try {
      return await callApi({
        path: `/api/studentForgotPassword`,
        method: "POST",
        body: { username },
      });
      // eslint-disable-next-line no-catch-all/no-catch-all
    } catch (error: any) {
      const { usernameNotFound, parentNotConfirmed } = errors.forgotPassword;

      if (matchesError(error.response, "no parent confirmed")) {
        return parentNotConfirmed();
      }
      return usernameNotFound();
    }
  },
});

interface CheckUsernameOperationParams {
  username: string;
}

export const useCheckUsernameOperation = makeMutation<CheckUsernameOperationParams, { status: string }>({
  name: "checkUsername",
  fn: async ({ username }) => {
    if (!username) {
      return { status: "Please add a username" };
    }

    try {
      return await callApi({
        path: `/api/username/${username}`,
        method: "GET",
      });
      // eslint-disable-next-line no-catch-all/no-catch-all
    } catch (error: any) {
      let status: string = error?.response?.body?.status;
      if (!status) {
        try {
          status = error?.response?.status;
          // eslint-disable-next-line no-catch-all/no-catch-all
        } catch {
          // no op
        }
      }

      if (status === "taken") {
        return error;
      }

      return { status: "invalid username" };
    }
  },
});

interface ValidateGoogleCodeOperationParams {
  code: string;
  state: string;
}

export const useValidateGoogleCodeOperation = makeMutation<
  ValidateGoogleCodeOperationParams,
  components["schemas"]["DojoSessionResponse"]
>({
  name: "validateGoogleCode",
  fn: async ({ code, state }) => {
    try {
      return (
        await callApi({
          method: "POST",
          path: "/api/session",
          body: {
            googleToken: code,
            state,
          },
        })
      ).body;
      // eslint-disable-next-line no-catch-all/no-catch-all
    } catch (err) {
      return err;
    }
  },
});

export const useGoToGoogleAuthOperation = makeMutation<Record<string, never>, { url: string }>({
  name: "goToGoogleAuth",
  fn: async () => {
    const response = await callApi({
      method: "GET",
      path: "/api/studentGoogleLoginUrl",
    });

    window.location = response.body.url;

    return response.body;
  },
});

interface LinkStudentWithGoogleAccountOperationParams {
  studentUserId: string;
  classTextCode: string;
  studentId: string;
}

export const useLinkStudentWithGoogleAccountOperation = makeMutation<LinkStudentWithGoogleAccountOperationParams, void>(
  {
    name: "linkStudentWithGoogle",
    fn: async ({ studentUserId, classTextCode, studentId }) => {
      try {
        return await callApi({
          method: "POST",
          path: `/api/studentUser/${studentUserId}/student`,
          body: {
            classTextCode,
            studentId,
          },
        });
        // eslint-disable-next-line no-catch-all/no-catch-all
      } catch (err: any) {
        return err;
      }
    },
    onSuccess: () => {
      useStudentsFetcher.invalidateQueries();
    },
  },
);

export const useLogOutOperation = makeMutation<Record<string, never>, void>({
  name: "logOutOperation",
  fn: async () => {
    const onLogOutDone = () => {
      const { token } = getLocalTokenData();

      // Do a page reload to make sure no redux or saga state persists from one user to the next
      if (token) {
        window.location.replace(`/#/tokenLogin?token=${token}`);
        window.location.reload();
      } else {
        window.location.replace("/#/login");
        window.location.reload();
      }
    };

    try {
      await callApi({
        path: `/api/session`,
        method: "DELETE",
      });
      onLogOutDone();
    } catch (error: any) {
      // Even if we get a 401 we wanted to log out so lets go to
      // login page
      if (error.response?.status === 401) {
        onLogOutDone();
      } else {
        throw error;
      }
    }
  },
  onSuccess: () => {
    useSessionFetcher.invalidateQueries();
  },
});

interface LoginOperationParams {
  username: string;
  password: string;
}

export const useLoginOperation = makeMutation<
  LoginOperationParams,
  { body: components["schemas"]["DojoSessionResponse"] },
  DojoError
>({
  name: "loginOperation",
  fn: async ({ username, password }) => {
    try {
      const response = await callApi({
        method: "POST",
        path: "/api/session",
        body: { login: username, password },
      });

      if (!response.body.student) {
        return errors.login.incorrectUserType();
      }

      return response;
    } catch (error: any) {
      const remainingAttempts = error?.response?.header?.["remaining-attempts"];

      if (matchesError(error.response, "Incorrect password") || matchesError(error.response, "Incorrect username")) {
        const error = errors.login.invalid();
        error.remainingAttempts = parseInt(remainingAttempts, 10);
        return error;
      }

      if (matchesError(error.response, "Too many login attempts, user is temporarily locked out of login")) {
        return errors.login.lockout();
      }

      if (error?.response?.body?.error?.code?.startsWith("ERR_MUST_USE_OTC")) {
        return errors.login.mustUseOtc();
      }

      throw error;
    }
  },
  onSuccess: () => {
    useSessionFetcher.invalidateQueries();
  },
});

interface StudentLogoutOperationParams {
  redirect?: () => void;
}

// This endpoint only logs out the student, not any logged in P/T
export const useStudentLogoutOperation = makeMutation<StudentLogoutOperationParams, void>({
  name: "studentLogoutOperation",
  fn: async ({ redirect } = {}) => {
    try {
      await callApi({
        method: "POST",
        path: `/api/sessionEntityDelete`,
      });

      if (redirect) {
        redirect();
      }

      return;
    } catch (error: any) {
      if (error.response?.status < 500) {
        if (redirect) {
          redirect();
        }

        return;
      }
      throw error;
    }
  },
});

export const useSessionFetcher = makeMemberQuery({
  fetcherName: "sessionFetcher",
  path: "/api/session",
  query: { includeExtras: "location" },
  dontThrowOnStatusCodes: [401],
  onSuccess: (data) => {
    const studentId = data?.student?._id;
    if (studentId) {
      useStudentsFetcher.invalidateQueries({ studentUserId: studentId });
    }
  },
});

const _useModifySession = makeApiMutation({
  name: "modifySession",
  path: "/api/session",
  method: "patch",
});

export const useModifySession = () => {
  const { mutate: modifySession } = _useModifySession({});
  return {
    makeSessionReadOnly: useCallback(() => modifySession({ body: { readOnly: true } }), [modifySession]),
    makeSessionWritable: useCallback(() => modifySession({ body: { readOnly: false } }), [modifySession]),
  };
};
