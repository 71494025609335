// TODO: We want to remove this once we move away from the redux error handling
import error from "@classdojo/web/pods/errorHandling";
import i18nPodInstaller from "@classdojo/web/pods/i18n";
import webcamPermissions from "@classdojo/web/pods/webcamPermissions";
import points from "app/pods/points";
import story from "app/pods/story";
import student from "app/pods/student";
import tokenLogin from "app/pods/tokenLogin";
import ui from "app/pods/ui";

export default [i18nPodInstaller, error, student, story, points, tokenLogin, webcamPermissions, ui];
