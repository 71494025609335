import * as logClient from "@classdojo/log-client";
import { NOOP, WAITING_FOR_DEPENDENCIES, makeApiMutation, makeCollectionQuery } from "@web-monorepo/shared/reactQuery";
import { APIResponse } from "app/utils/apiTypesHelper";
import { useEffect, useMemo, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { selectLocale } from "@classdojo/web/pods/i18n";
import { useSelector } from "react-redux";
import { useFetchedStudent } from "app/pods/student";
import { lastInitial } from "@classdojo/web/utils/name";
import { getHours, getISOWeekday } from "@web-monorepo/dates";
import { useMonsterConfigFetcher } from "app/pods/monsterWorld";
import groupBy from "lodash/groupBy";
import { getImageSource } from "#/app/utils/Image";
import { getIsAdminSessionAndIsReadOnlySession } from "@web-monorepo/shared/utils/session";

export type HomeSeason = "spookySeason" | "winterSeason";

export const HOME_ISLANDS_COLORS = {
  SCHOOL_TOGGLE_BACKGROUND_COLOR: "#87F0C4",
  HOME_TOGGLE_BACKGROUND_COLOR: "#FFDFB3",
  ISLAND_SIDE_SHEET_BACKGROUND_COLOR: "#195294",
  ISLAND_LABEL_COLOR_COMMUNITY: "#FFDFB3",
};

export type CommunityIsland = {
  islandId: string;
  islandType: "community";
  islandRef: string;
  islandName: string;
  islandIconUrl: string;
  enabled: boolean;
  members: Array<{ entityId: string; avatarUrl?: string; plot: { islandId: string } }>;
};

export const MAX_COMMUNITY_ISLANDS = 5;
export const MAX_COMMUNITY_ISLAND_MEMBERS = 30;

export const useDojoIslandsFetcher = makeCollectionQuery({
  path: "/api/studentUser/{studentUserId}/islands",
  fetcherName: "monsterverseDojoIslands",
  staleTime: 30_000,
  refetchOnWindowFocus: true,
  refetchOnMount: true,
});

export const useDojoIslandsAllIslandsOnlineNowFetcher = makeCollectionQuery({
  path: "/api/islands/online",
  fetcherName: "monsterverseAllIslandsOnlineNow",
  staleTime: 30_000,
  refetchOnWindowFocus: true,
  refetchOnMount: true,
});

export const useDojoIslandsGroups = ({ studentUserId }: { studentUserId: string | undefined }) => {
  const { data: islandData } = useDojoIslandsFetcher(studentUserId ? { studentUserId } : WAITING_FOR_DEPENDENCIES);

  return useMemo(() => {
    if (!studentUserId || !islandData) return {};

    const classIslands = [];
    const communityIslands = [];

    for (const island of islandData) {
      switch (island.islandType) {
        case "class":
          classIslands.push({
            islandId: island.islandId,
            islandType: island.islandType,
            islandRef: `class-${island.islandId}`,
            islandName: island.className,
            islandIconUrl: island.iconUrl,
            iconBackgroundColor: island.iconBackgroundColor,
            members: island.members,
            enabled: island.enabled,
            lockedReason: island.lockedReason,
            teacher: island.teacher,
          });
          break;
        case "community":
          communityIslands.push({
            islandId: island.islandId,
            islandType: island.islandType,
            islandRef: `community-${island.islandId}`,
            islandName: island.islandName,
            islandIconUrl: island.islandIconUrl,
            iconBackgroundColor: "",
            members: island.members,
            enabled: island.enabled,
            lockedReason: island.lockedReason,
            lastAccess: island.lastAccess,
          });
          break;
      }
    }

    return {
      classIslands,
      communityIslands,
    };
  }, [islandData, studentUserId]);
};

export const useDojoIslandDetails = (islandId: string | undefined, studentUserId: string | undefined) => {
  const { communityIslands, classIslands } = useDojoIslandsGroups({ studentUserId });

  const island = useMemo(() => {
    if (!islandId) {
      return;
    }

    return (
      communityIslands?.find((island) => island.islandId === islandId) ||
      classIslands?.find((island) => island.islandId === islandId)
    );
  }, [classIslands, communityIslands, islandId]);

  return island;
};

export const randomizeNameAndIcon = ({
  islandIcons,
  islandNames,
}: {
  islandIcons: { iconURL: string }[];
  islandNames: { name: string }[];
}) => {
  const indexIcon = Math.floor(Math.random() * islandIcons?.length) % islandIcons?.length;
  const indexName = Math.floor(Math.random() * islandNames?.length) % islandNames?.length;
  return { islandName: islandNames[indexName].name, islandIconUrl: islandIcons[indexIcon].iconURL };
};

export const useCreateFirstCommunityIsland = (hasCommunityIslands: boolean, canAddIslands: boolean) => {
  const student = useFetchedStudent();
  const studentUserId = student?._id;
  const locale = useSelector(selectLocale);

  const createdFirstCommunityIsland = useRef(false);
  const shouldCreateFirstIsland = !createdFirstCommunityIsland.current && !hasCommunityIslands;

  const { data: islandNames } = useDojoIslandsNamesFetcher(shouldCreateFirstIsland ? { locale } : NOOP);
  const { data: islandIcons } = useDojoIslandsIconsFetcher(shouldCreateFirstIsland ? {} : NOOP);
  const { data: friends } = useDojoIslandsFriendsFetcher(
    shouldCreateFirstIsland && studentUserId ? { studentUserId } : NOOP,
  );

  const { mutateAsync: newCommunityIsland } = useNewCommunityIslandOperation();

  useEffect(() => {
    if (canAddIslands && shouldCreateFirstIsland && studentUserId && islandIcons && islandNames && friends) {
      createdFirstCommunityIsland.current = true;

      const membersPerType = groupBy(
        friends.filter((friend) => friend.entityType === "student"),
        "relation",
      );
      const initialMembers: {
        entityType: "student";
        entityId: string;
      }[] = [];

      // select max 29 members to join player on initial island
      monsterverseFriendsOrder.forEach((relationType) => {
        if (!initialMembers.length && membersPerType[relationType]?.length) {
          initialMembers.push(
            ...membersPerType[relationType].map((member) => ({
              entityType: "student" as const,
              entityId: member.entityId,
            })),
          );
        }
      });
      const members = initialMembers.slice(0, MAX_COMMUNITY_ISLAND_MEMBERS - 1);

      try {
        newCommunityIsland({
          path: { studentUserId },
          body: {
            islandType: "community",
            ...randomizeNameAndIcon({ islandIcons, islandNames }),
            locale,
            members: [{ entityId: studentUserId, entityType: "student" }, ...members],
          },
        });
        // eslint-disable-next-line no-catch-all/no-catch-all
      } catch (err: any) {
        logClient.logMessage("WARN", `Error while creating the first community island ${err.message}`);
      }
    }
  }, [
    canAddIslands,
    shouldCreateFirstIsland,
    studentUserId,
    islandIcons,
    islandNames,
    friends,
    locale,
    newCommunityIsland,
  ]);
};

export const useNewCommunityIslandOperation = makeApiMutation({
  name: "newCommunityIsland",
  path: "/api/studentUser/{studentUserId}/islands",
  method: "post",
  onSuccess: (_data, params) => {
    useDojoIslandsFetcher.invalidateQueries({ studentUserId: params.path.studentUserId });
  },
});

export const useEditCommunityIslandOperation = makeApiMutation({
  name: "editCommunityIsland",
  path: "/api/studentUser/{studentUserId}/islands/{islandId}",
  method: "put",
  onSuccess: (_data, params) => {
    useDojoIslandsFetcher.invalidateQueries({ studentUserId: params.path.studentUserId });
  },
});

export const useAddCommunityIslandMembersOperation = makeApiMutation({
  name: "addCommunityIslandMembers",
  path: "/api/islands/{islandId}/members",
  method: "post",
  onSuccess: (_data, _params) => {
    useDojoIslandsFetcher.invalidateQueries();
  },
});

export const useLeaveCommunityIslandOperation = makeApiMutation({
  name: "leaveCommunityIsland",
  path: "/api/islands/{islandId}/members/{entityId}",
  method: "delete",
  onSuccess: (_data, _params) => {
    useDojoIslandsFetcher.invalidateQueries();
  },
});

export type MonsterverseFriend = APIResponse<"/api/studentUser/{studentUserId}/friends", "get">["_items"][0];

export const useDojoIslandsFriendsFetcher = makeCollectionQuery({
  path: "/api/studentUser/{studentUserId}/friends",
  fetcherName: "monsterverseDojoIslandsFriends",
});

export const monsterverseFriendsOrder: MonsterverseFriend["relation"][] = [
  "classmate",
  "sibling",
  "inactive_classmate",
  "former_classmate",
  "same_grade_schoolmate",
];
export const sortMonsterverseFriends = (friend1: MonsterverseFriend, friend2: MonsterverseFriend) => {
  if (friend1.relation === friend2.relation) {
    return lastInitial(friend1).toLocaleLowerCase() < lastInitial(friend2).toLocaleLowerCase() ? -1 : 1;
  }
  return monsterverseFriendsOrder.indexOf(friend1.relation) - monsterverseFriendsOrder.indexOf(friend2.relation);
};

export const filterClassmatesOrFirst50 = (friend: MonsterverseFriend, index: number) => {
  return friend.relation === "classmate" || friend.relation === "sibling" || index < 50;
};

export const useDojoIslandsIconsFetcher = makeCollectionQuery({
  path: "/api/islands/icons",
  fetcherName: "monsterverseDojoIslandsIcons",
});

export const useDojoIslandsNamesFetcher = makeCollectionQuery({
  path: "/api/islands/names",
  fetcherName: "monsterverseDojoIslandsNames",
  queryParams: ["locale"],
});

export const useIsHomeIslandsSmallDevice = () => {
  const isSmallDeviceLandscape = useMediaQuery({ maxHeight: 575, orientation: "landscape" });
  const isSmallDevicePortrait = useMediaQuery({ maxWidth: 767, orientation: "portrait" });
  return isSmallDeviceLandscape || isSmallDevicePortrait;
};

export type IslandMember = {
  entityId: string;
  entityType: "parent" | "student" | "teacher";
  firstName?: string;
  lastName?: string;
  avatarUrl?: string;
  studentUser?: { _id: string };
};

export const getIslandMemberEntityId = (member: { entityId: string; studentUser?: { _id: string } }) =>
  "studentUser" in member ? member.studentUser?._id : member.entityId;

export const useHomeIslandsMembers = () => {
  const student = useFetchedStudent();
  const studentUserId = student?._id;
  const { communityIslands, classIslands } = useDojoIslandsGroups({ studentUserId });
  const { data: onlineNowData } = useDojoIslandsAllIslandsOnlineNowFetcher({});

  const islandMembers = useMemo(() => {
    if (!onlineNowData) {
      return;
    }

    const islandMembers: Record<string, { onlineMembers: IslandMember[]; offlineMembers: IslandMember[] }> = {};

    communityIslands?.forEach((island) => {
      const onlineMembers: Array<IslandMember> = [];
      const offlineMembers: Array<IslandMember> = [];
      const onlineMembersData =
        onlineNowData?.find((data) => data.islandType === island.islandType && data.islandId === island.islandId)
          ?.online || [];
      const onlineMembersDataEntityIds = onlineMembersData.map((member) => member.entityId);

      island?.members.forEach((member) => {
        const entityId = getIslandMemberEntityId(member);
        if (entityId && onlineMembersDataEntityIds.includes(entityId)) {
          onlineMembers.push(member);
        } else {
          offlineMembers.push(member);
        }
      });

      islandMembers[island.islandId] = { onlineMembers, offlineMembers };
    });

    classIslands?.forEach((island) => {
      const onlineMembers: Array<IslandMember> = [];
      const offlineMembers: Array<IslandMember> = [];
      const onlineMembersData =
        onlineNowData?.find((data) => data.islandType === island.islandType && data.islandId === island.islandId)
          ?.online || [];
      const onlineMembersDataEntityIds = onlineMembersData.map((member) => member.entityId);

      island?.members.forEach((member) => {
        const entityId = getIslandMemberEntityId(member);
        if (entityId && onlineMembersDataEntityIds.includes(entityId)) {
          onlineMembers.push(member);
        } else {
          offlineMembers.push(member);
        }
      });

      islandMembers[island.islandId] = { onlineMembers, offlineMembers };
    });

    return islandMembers;
  }, [classIslands, communityIslands, onlineNowData]);

  return islandMembers;
};

export const isHomeHours = (date: Date): boolean => {
  // Saturday and Sunday => Home
  if ([6, 7].includes(getISOWeekday(date))) {
    return true;
  }

  // Mon-Fri - 3PM to 7AM => Home
  if (getHours(date) >= 15 || getHours(date) < 7) {
    return true;
  }

  return false;
};

export const useIsHomeIslandsEligible = () => {
  const { data: monsterverseConfig } = useMonsterConfigFetcher({});
  return (monsterverseConfig?.type === "student" && monsterverseConfig?.homeIslandsEligible) || false;
};

export const useIsHomeIslandsAvailable = () => {
  const { data: monsterverseConfig, isInitialLoading: isLoading } = useMonsterConfigFetcher({});
  const { isAdminSession, isReadOnlySession } = getIsAdminSessionAndIsReadOnlySession();

  const homeIslandsAvailable =
    monsterverseConfig?.type === "student" &&
    (monsterverseConfig.homeIslandsAvailable || isAdminSession || isReadOnlySession);

  return { homeIslandsAvailable, isLoading };
};

export const useGetsHomeSeason = (): { current?: HomeSeason; isLoading: boolean } => {
  const { data: monsterverseConfig, isInitialLoading: isLoading } = useMonsterConfigFetcher({});

  if (monsterverseConfig?.type === "student") {
    const { spookySeason, winterSeason } = monsterverseConfig.homeSeasonalContent;

    if (spookySeason?.showContent) {
      return { current: "spookySeason", isLoading };
    }
    if (winterSeason?.showContent) {
      return { current: "winterSeason", isLoading };
    }
  }

  return { isLoading };
};

const resolveSeasonPath = (season: HomeSeason | undefined, path: string) => {
  switch (season) {
    case "spookySeason":
      return path.replace("/islands_map/", "/islands_map/spooky/");
    case "winterSeason":
      return path.replace("/islands_map/", "/islands_map/winter/");
    case undefined:
      return path;
    default:
      season satisfies never;
      return path;
  }
};

export const getImageSourceForSeason = (season: HomeSeason | undefined, path: string) => {
  const resolvedPath = resolveSeasonPath(season, path);
  return getImageSource(resolvedPath);
};
