import type { UploadParams, OpenUppyDialogParams, UploadedFileInfo as InnerUploadedFileInfo } from "./sync";

export const IMAGES_ALLOWED_TYPES = [".jpg", ".jpeg", ".png", ".gif", ".bmp", ".tiff"];
export type UploadedFileInfo = InnerUploadedFileInfo;

export type VideoFileInfo = {
  blob: Blob;
  duration?: number;
  width?: number;
  height?: number;
};

export function openUppyDialog(args: OpenUppyDialogParams): Promise<void> {
  return import("./sync").then((m) => m.openUppyDialog(args));
}

export function uploadViaUppy(args: UploadParams): Promise<UploadedFileInfo | UploadedFileInfo[] | null> {
  return import("./sync").then((m) => m.uploadViaUppy(args));
}
