// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconSearchThick = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.3763 20.175L16.9088 14.5837C16.875 14.55 16.8713 14.4937 16.905 14.445C19.2225 11.07 18.8325 6.49124 15.9825 3.55499C14.2013 1.72124 11.7113 0.772494 9.15752 0.959994C6.81377 1.13249 4.68377 2.22374 3.15752 4.03874C0.48752 7.21874 0.51002 12.0037 3.21002 15.165C5.02127 17.2837 7.64627 18.3825 10.4175 18.18C11.8763 18.075 13.3013 17.5875 14.5275 16.7775C14.565 16.7512 14.625 16.7437 14.6625 16.785L20.1788 22.4287C20.475 22.7325 20.88 22.9012 21.3 22.9012C21.3375 22.9012 21.3788 22.9012 21.4163 22.8975C21.765 22.8712 22.0988 22.7287 22.3575 22.4962C22.6613 22.2225 22.8338 21.8362 22.845 21.4087C22.86 20.9512 22.6838 20.5012 22.3688 20.1787L22.3763 20.175ZM15.2813 9.65249C15.2588 11.1187 14.67 12.4912 13.6163 13.5112C12.69 14.4112 11.475 14.955 10.1925 15.0487C8.56877 15.1687 6.98252 14.565 5.85002 13.3987C4.82627 12.345 4.27502 10.9575 4.29752 9.49124C4.32002 8.02499 4.90877 6.65249 5.96252 5.63249C6.88502 4.73249 8.10377 4.18874 9.38627 4.09499C9.52127 4.08374 9.65627 4.07999 9.79127 4.07999C11.2725 4.07999 12.69 4.67624 13.7288 5.74499C14.7525 6.79874 15.3038 8.18624 15.2813 9.65249Z" />
    </svg>
  );
};
