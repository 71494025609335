import { CollectionFetcherReturnType } from "@web-monorepo/shared/api/apiTypesHelper";
import { makeCollectionQuery } from "@web-monorepo/shared/reactQuery";

export type RenderedAward = CollectionFetcherReturnType<typeof useStudentAwardsFetcher>;

export const useStudentAwardsFetcher = makeCollectionQuery({
  path: "/api/award",
  queryParams: ["from", "to"],
  fetcherName: "studentAwards",
});

export default function install() {}
