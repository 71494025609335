import * as logClient from "@classdojo/log-client";
import { components } from "@classdojo/ts-api-types/api";
import callApi from "@web-monorepo/infra/callApi";
import { makeMutation } from "@web-monorepo/shared/reactQuery";
import errors from "app/errors";
import { ACCOUNT_SWITCHER_KEY, useSessionFetcher } from "app/pods/auth";
import { localStorage } from "@web-monorepo/safe-browser-storage";
import { captureException } from "@web-monorepo/telemetry";

export interface ClassStudentByToken {
  _id: components["schemas"]["MongoId"];
  firstName: string;
  lastName: string;
  defaultAvatar: string;
  avatar: string;
  studentUser?: {
    username?: string;
    emailAddress?: string;
  };
  loginCode: string;
}

export type ClassStudentsByToken = ClassStudentByToken[];

export default function install() {}

const TOKEN_LOGIN_DATA_KEY = "__STUDENT_LOGIN__";

// always returns an object so destructuring is safe
export function getLocalTokenData() {
  try {
    return JSON.parse(localStorage.getItem(TOKEN_LOGIN_DATA_KEY) || "{}") as any;
    // eslint-disable-next-line no-catch-all/no-catch-all
  } catch {
    return {};
  }
}

export function clearLocalTokenData() {
  localStorage.removeItem(TOKEN_LOGIN_DATA_KEY);
}

export function clearAccountSwitcherLocalStorage() {
  localStorage.removeItem(ACCOUNT_SWITCHER_KEY);
}

interface TokenLoginOperationParams {
  studentId: string;
  token: string;
  classId?: string;
}

export const useTokenLoginOperation = makeMutation<
  TokenLoginOperationParams,
  components["schemas"]["DojoSessionResponse"]
>({
  name: "tokenLogin",
  useErrorBoundary: false,
  fn: async ({ studentId, token, classId }) => {
    clearAccountSwitcherLocalStorage();
    try {
      const response = await callApi({
        path: `/api/session`,
        method: "POST",
        body: { studentId, token, classId },
      });
      if (response.body.student) logClient.setEntityId(response.body.student._id);
      logClient.logEvent({
        eventName: "web.login.success",
        metadata: {
          current_site: "student",
          account_type: "student",
          login_type: "student_token",
        },
      });
      return response.body;
      // eslint-disable-next-line no-catch-all/no-catch-all
    } catch (error: any) {
      captureException(error, { extra: { classId } });
      logClient.logEvent({
        eventName: "web.login.failure",
        metadata: {
          current_site: "student",
          account_type: "student",
          login_type: "student_token",
          failure_readon: error.response,
        },
      });
      throw error;
    }
  },
  onSuccess: () => {
    useSessionFetcher.invalidateQueries();
  },
});

export const useDirectTokenLoginOperation = makeMutation<
  TokenLoginOperationParams,
  { body: components["schemas"]["DojoSessionResponse"] }
>({
  // Note - this is a parallel of useTokenLoginOperation above, but without a saga watching it and redirecting
  // https://classdojo.slack.com/archives/C7UQ5JQG3/p1602871508086800
  name: "directTokenLogin",
  fn: async ({ studentId, token, classId }) => {
    try {
      const response = await callApi({
        path: `/api/session`,
        method: "POST",
        body: { studentId, token, classId },
      });

      if (response.body.student) logClient.setEntityId(response.body.student._id);

      // cancel any in-progress session requests so we don't have timing issues
      // with responses for a different user
      await useSessionFetcher.cancelQueries();
      // set session data with the response from the POST request
      // to update the local session state with the user info we just logged in with
      useSessionFetcher.setQueriesData(response.body);

      return response;
    } catch (error: any) {
      if (error.response && (error.response.status === 400 || error.response.status === 401)) {
        return errors.login.invalid();
      }
      throw error;
    }
  },
  onSuccess: () => {
    useSessionFetcher.invalidateQueries();
  },
});

type FetchStudentsByTokenOperationResponse = {
  students: ClassStudentsByToken;
  classId: string;
  token: string;
};

export const useFetchStudentsByTokenOperation = makeMutation<{ token: string }, FetchStudentsByTokenOperationResponse>({
  name: "fetchStudentsByToken",
  fn: async ({ token }) => {
    try {
      const auth = btoa(`${token}:${token}`);

      const response = await callApi({
        method: "GET",
        path: "/api/classStudentByToken",
        headers: {
          Authorization: `Basic ${auth}`,
        },
      });

      const { classId } = response.body._metadata;
      const students = response.body._items;

      logClient.logEvent({ eventName: "student_qr_login", eventValue: classId });

      // store login token info in local storage so we can persist through a refresh
      localStorage.setItem(TOKEN_LOGIN_DATA_KEY, JSON.stringify({ classId, token }));

      return { students, classId, token };
    } catch (error: unknown) {
      if (error instanceof Error) {
        return error;
      }
      throw error;
    }
  },
});

type UpdateSessionTokenOperationParams = {
  classId: string;
};
export const useUpdateSessionTokenOperation = makeMutation<UpdateSessionTokenOperationParams, void>({
  name: "updateSessionToken",
  fn: async ({ classId }) => {
    try {
      await callApi({
        path: "/api/sessionToken/current",
        method: "PUT",
        body: { classId },
      });
    } catch (error: unknown) {
      if (error instanceof Error) {
        // log and ignore errors, since this is not critical to app
        logClient.logMessage(
          "WARN",
          `Error occurred when using useUpdateSessionTokenOperation operation. ${error.message}`,
        );
        return;
      }
      throw error;
    }
  },
  onMutate: ({ classId }) => {
    useSessionFetcher.setQueriesData((cachedData) => {
      cachedData.selectedClassId = classId;
    });
  },
  onSuccess: () => {
    useSessionFetcher.invalidateQueries();
  },
});
