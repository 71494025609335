import { logEvent } from "@classdojo/log-client";
import PubnubClient from "@classdojo/web/pods/realtime/adapters/pubnub";
import { Message } from "@classdojo/web/pods/realtime/adapters/types";
import { useDojoIslandsAllIslandsOnlineNowFetcher, useDojoIslandsFetcher } from "app/pods/homeIslands";
import env from "app/utils/env";
import { metrics } from "app/utils/metrics";

/**
 * This module holds a singleton Pubnub client.
 */

type PubnubClientMock = {
  subscribe: () => void;
  unsubscribe: () => void;
};

const PUBNUB_CLIENT_ID_COOKIE = "dojo_pubnub_clientId";

const pubnubKeyCookie = document.cookie
  .split("; ")
  .find((cookie) => cookie.startsWith(`${PUBNUB_CLIENT_ID_COOKIE}`))
  ?.split("=")[1];

let clientId = pubnubKeyCookie;

if (env.isTesting) {
  clientId = "dojo-testingEnv";
}

if (!clientId) {
  const tempArray = new Uint32Array(10);
  const crypto = window.crypto || window.msCrypto;
  const randomNumbers = pubnubKeyCookie ?? crypto.getRandomValues(tempArray)[0];
  clientId = `dojo-${randomNumbers}`;
  document.cookie = `${PUBNUB_CLIENT_ID_COOKIE}=${clientId}`;
}

let client: PubnubClient | PubnubClientMock = {
  subscribe() {},
  unsubscribe() {},
};

function log(...args: unknown[]) {
  if (env.isDev && !global.hideLogs) {
    // eslint-disable-next-line no-console
    console.log("PUBNUB - ", ...args);
  }
}

const PubnubUtil = {
  client,
  /**
   * @param  {String} studentId
   * @return {String}
   */
  init: function init(studentId: string) {
    if (!env.isTesting) {
      client = new PubnubClient({ metrics, logEvent, userId: studentId });
    }
  },

  /**
   * @param {String} channel
   */
  subscribe: function subscribe(channel: string | string[]) {
    log("Subscribing to channels", channel);
    client.subscribe(channel, this.onMessage);
  },

  /**
   * @param {String} channel
   */
  unsubscribe: function unsubscribe(channel: string | string[]) {
    log("Unsubscribed from channels:", channel);
    client.unsubscribe(channel, this.onMessage);
  },

  /**
   * @param {Object} message
   */
  onMessage: (message: Message, channel?: string) => {
    // let's avoid echo chamber
    if (message.source_id === clientId) {
      return;
    }

    const command = message.command || message.action;
    log(`PUBNUB - Received ${command}`, message, channel);

    if (command === "refresh:mv_online_now") {
      const islandRef = message.islandRef as string;
      if (islandRef) {
        useDojoIslandsAllIslandsOnlineNowFetcher.invalidateQueries();
      }
    }
    if (command === "added_to_island") {
      useDojoIslandsFetcher.invalidateQueries();
    }
  },
};

export default PubnubUtil;
