import { ParsedUrlQueryInput } from "querystring";
import * as logClient from "@classdojo/log-client";
import { selectLocale } from "@classdojo/web/pods/i18n";
import get from "lodash/get";
import { DevToolsSettings } from "app/pods/devTools";
import { setFeatureSwitchesConfig } from "app/pods/featureSwitches";
import {
  STUDENT_FEATURE_SWITCHES,
  SCHOOL_FEATURE_SWITCHES,
  SESSION_FEATURE_SWITCHES,
} from "app/pods/featureSwitches/constants";
import { useFetchedStudent } from "app/pods/student";
import { useSelector } from "app/utils/reduxHooks";
export { FEATURE_SWITCHES } from "app/pods/featureSwitches/constants";

// This hook is called when loading the feature switches and it returns the query string parameters
// to be sent to the API.
// If no parameter values are available yet, you can return `null` to delay the API call until
// they become available in a later render cycle.
const useBuildFeatureSwitchesQueryParamsHook = () => {
  // studentUser could be not yet initialized at this point (since feature switches
  // is one of the few fetchers that we run when bootstrapping the app in application container)
  const studentUser = useFetchedStudent();
  // A studentUser can be in at-max a single school
  const schoolId = get(studentUser, ["schoolIds", 0], null);
  const studentLocale = useSelector(selectLocale);
  const sessionId = logClient.getSessionId();

  // we would normally check if we are fetching user session at this point
  // and return `null` if we are, but that would cause AuthenticatedPagesContainer
  // to be re-mounted and fetch the session again, which would cause a loop
  // of API calls to session, so we don't do it

  const queryStringParams: ParsedUrlQueryInput = {};

  if (Object.values(STUDENT_FEATURE_SWITCHES).length && studentUser) {
    // only load feature switches associated with studentUser if user is logged in
    queryStringParams.studentId = studentUser._id;
    queryStringParams.studentLocale = studentLocale;
    queryStringParams.studentSwitches = encodeURIComponent(Object.values(STUDENT_FEATURE_SWITCHES).join(","));
  }

  if (Object.values(SCHOOL_FEATURE_SWITCHES).length && schoolId) {
    queryStringParams.schoolId = schoolId;
    queryStringParams.schoolSwitches = encodeURIComponent(Object.values(SCHOOL_FEATURE_SWITCHES).join(","));
  }

  // We choose 'session' as the name for the group when reaching the case of a logged out user.
  if (Object.values(SESSION_FEATURE_SWITCHES).length && sessionId) {
    queryStringParams.sessionId = sessionId;
    queryStringParams.sessionSwitches = encodeURIComponent(Object.values(SESSION_FEATURE_SWITCHES).join(","));
  }
  return queryStringParams;
};

export function setAppFeatureSwitchesConfig() {
  setFeatureSwitchesConfig({
    featureSetIdLocalStorageKey: `student/featureSwitches/featureSetId`,
    useBuildFeatureSwitchesQueryParamsHook,
    useFeatureSwitchOverridesHook: () => {
      const { featureSwitchOverrides } = DevToolsSettings.useFeatureSwitchOverrides();
      return featureSwitchOverrides;
    },
    featureSwitchesFetcherQueryParams: [
      "studentId",
      "studentLocale",
      "studentSwitches",
      "schoolId",
      "schoolSwitches",
      "sessionId",
      "sessionSwitches",
    ],
  });
}
