import combineActionHandlers from "@web-monorepo/infra/combineActionHandlers";
import createAction from "@web-monorepo/infra/createAction";
import createActionHandler from "@web-monorepo/infra/createActionHandler";
import type { AnyAction } from "redux";

type UIState = { isAtBottom: boolean };

const STATE_KEY = "ui";

const initialState: UIState = {
  isAtBottom: false,
};

const SET_CONTENT_SCROLL = createAction("ui/set-scrolled-to-bottom");

export const setIsScrollAtBottom = (isAtBottom: boolean) => ({
  type: SET_CONTENT_SCROLL,
  payload: { isAtBottom },
});

const setContentScrollPositionHandler = createActionHandler(
  SET_CONTENT_SCROLL,
  ({ isAtBottom }: { isAtBottom: boolean }) => ({
    isAtBottom,
  }),
);

type UISlice = Record<typeof STATE_KEY, UIState>;

export const selectIsScrolledToBottom = (state: UISlice) => state[STATE_KEY].isAtBottom;

const reducer = combineActionHandlers(initialState, [setContentScrollPositionHandler]);

export default (installReducer: (key: string, reducer: (state: UISlice, action: AnyAction) => void) => void) => {
  installReducer(STATE_KEY, reducer);
};
