import { components } from "@classdojo/ts-api-types/api";
import callApi from "@web-monorepo/infra/callApi";
import { CollectionFetcherReturnType } from "@web-monorepo/shared/api/apiTypesHelper";
import { WAITING_FOR_DEPENDENCIES, makeCollectionQuery, makeMutation } from "@web-monorepo/shared/reactQuery";
import { useMemo } from "react";
import { useSessionFetcher } from "app/pods/auth";
import isAutoCreatedUser from "app/utils/isAutoCreatedUser";
import matchesError from "app/utils/matchesError";

export type RenderedStudent = CollectionFetcherReturnType<typeof useStudentsFetcher>;
export type RenderedStudentUserObject = components["schemas"]["RenderedStudentUserObject"];

export const doesStudentNeedApproval = (studentUser?: { age?: number; approved?: boolean }): boolean => {
  if (isAutoCreatedUser() || !studentUser) {
    return false;
  } else {
    return Boolean(studentUser.age && studentUser.age < 13 && !studentUser.approved);
  }
};

// To edit the avatar you need to have a PermissionToken, be over 13, or approved by your parent.
export const canStudentEditAvatar = (studentUser?: { age?: number; approved?: boolean }): boolean => {
  if (isAutoCreatedUser() || (studentUser && studentUser.approved)) return true;
  if (studentUser && studentUser.age && studentUser.age >= 13) return true;

  return false;
};

export default function install() {}

export const useFetchedStudent = (): RenderedStudentUserObject | undefined => {
  const { data } = useSessionFetcher({});
  const student = data && data.student;
  return student;
};

interface DeleteAccountOperationParams {
  studentId: string;
}

export const useDeleteAccountOperation = makeMutation<DeleteAccountOperationParams, unknown>({
  name: "deleteAccount",
  fn: async ({ studentId }) => {
    return await callApi({
      path: `/api/studentUser/${studentId}`,
      method: "DELETE",
    });
  },
});

interface DeleteAccountCheckingPasswordOperationParams {
  password: string;
  username: string;
  studentId: string;
}

export const useDeleteAccountCheckingPasswordOperation = makeMutation<
  DeleteAccountCheckingPasswordOperationParams,
  unknown
>({
  name: "deleteAccountCheckingPassword",
  fn: async ({ password, username, studentId }) => {
    try {
      await callApi({
        path: `/api/session`,
        method: "POST",
        body: { login: username, password },
      });

      return await callApi({
        path: `/api/studentUser/${studentId}`,
        method: "DELETE",
      });
    } catch (error: any) {
      if (matchesError(error.response, "Incorrect password")) {
        return error;
      } else {
        throw error;
      }
    }
  },
});

interface UpdateUsernameOperationParams {
  username: string;
  studentUserId: string;
}

export const useUpdateUsernameOperation = makeMutation<UpdateUsernameOperationParams, unknown>({
  name: "updateUsername",
  fn: async ({ studentUserId, username }) => {
    return await callApi({
      path: `/api/studentUser/${studentUserId}`,
      method: "PUT",
      body: { _id: studentUserId, username },
    });
  },
  onSuccess: (_data, params) => {
    useSessionFetcher.setQueriesData((draft) => {
      if (draft.student) {
        draft.student.username = params.username;
      }
    });
  },
});

// This fetcher is for notional students
// there can be multiple notional students for a given student user
// for more info on notional students see https://stackoverflow.com/c/classdojo/questions/10/11
export const useStudentsFetcher = makeCollectionQuery({
  path: "/api/studentUser/{studentUserId}/student",
  query: { excludeParentConnections: "true" },
  fetcherName: "student",
});

interface ChangePasswordOperationParams {
  password: string;
  username: string;
  studentUserId: string;
}

export const useChangePasswordOperation = makeMutation<ChangePasswordOperationParams, unknown>({
  name: "changePassword",
  fn: async ({ password, username, studentUserId }) => {
    return await callApi({
      path: `/api/studentUser/${studentUserId}`,
      method: "PUT",
      body: { _id: studentUserId, password, username },
    });
  },
});

export type RenderedClass = RenderedStudent["classes"][number];
export type AvailableClasses = Record<string, RenderedClass>;
export type AvailableClassesArr = RenderedClass[];

const getAvailableClasses = (students?: Pick<RenderedStudent, "_id" | "classes">[]): AvailableClasses => {
  if (!students) return {};

  return students.reduce(function (accumulator: AvailableClasses, { classes }) {
    classes.forEach(function (c) {
      accumulator[c._id] = c;
    });
    return accumulator;
  }, {});
};

export function useAvailableClassesById() {
  const student = useFetchedStudent();
  const { data: students } = useStudentsFetcher(
    student
      ? {
          studentUserId: student._id,
        }
      : WAITING_FOR_DEPENDENCIES,
  );
  return {
    ready: !!students,
    availableClasses: useMemo(() => getAvailableClasses(students), [students]),
  };
}

export function useAvailableClasses() {
  const { ready, availableClasses: classesById } = useAvailableClassesById();

  const availableClasses = useMemo(() => {
    const classes = Object.values(classesById);
    const sortedClassrooms = classes.sort((c1, c2) =>
      c1.name.toLocaleLowerCase() > c2.name.toLocaleLowerCase() ? 1 : -1,
    );

    return sortedClassrooms;
  }, [classesById]);

  return {
    ready,
    availableClasses,
  };
}

export function useNonArchivedClasses() {
  const { ready, availableClasses } = useAvailableClasses();
  return {
    ready,
    availableClasses: useMemo(() => availableClasses.filter((c) => !c.archived), [availableClasses]),
  };
}
