import { WAITING_FOR_DEPENDENCIES } from "@web-monorepo/shared/reactQuery";
import { MatomoRouterProvider } from "@web-monorepo/shared/utils/matomo";
import { useLocation } from "react-router-dom";
import { useSessionFetcher } from "app/pods/auth";
import { type FC, type PropsWithChildren } from "react";
/**
 * Matomo provider with configuration for teach.
 * It should be included within a Router so it has access to location.
 */
export const MatomoConfiguredProvider: FC<PropsWithChildren> = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  // we don't want to fetch the session if we are in the direct login with student token route
  // so it doesn't cause timing issues with the session post we are going to do (this was causing
  // occasional failures where users got redirected to login, even when they had correct token to login)
  const shouldFetchSession = location.pathname !== "/directLogin";
  const { data: session } = useSessionFetcher(shouldFetchSession ? {} : WAITING_FOR_DEPENDENCIES);
  const student = session && session.student;

  return (
    <MatomoRouterProvider
      userId={student?._id}
      urlBase="https://student.classdojo.com"
      domains={["*.student.classdojo.com"]}
    >
      {children}
    </MatomoRouterProvider>
  );
};
