import { makeMemberQuery } from "@web-monorepo/shared/reactQuery";

export const useMonsterConfigFetcher = makeMemberQuery({
  path: "/api/monsterverseConfig",
  fetcherName: "monsterverseConfig",
  queryParams: ["classId"],
  staleTime: 30_000,
  refetchOnWindowFocus: true,
  refetchOnMount: true,
});

export const useMonsterverseLinkValidateFetcher = makeMemberQuery({
  path: "/api/monsterverseLinks/validate",
  fetcherName: "monsterverseLinkValidateFetcher",
  queryParams: ["token"],
});

export const useMonsterverseLinkPlayModeFetcher = makeMemberQuery({
  path: "/api/monsterverseLinks/playMode",
  fetcherName: "monsterverseLinkPlayModeFetcher",
  queryParams: ["token"],
});

export const useMonsterverseEnabled = (classId?: string): boolean | undefined => {
  const { data: monsterverseConfig } = useMonsterConfigFetcher({ classId });
  return (monsterverseConfig?.type === "student" && monsterverseConfig.monsterverseEnabled) || false;
};

export const useMonsterverseParentConsentStatus = () => {
  const { data: monsterverseConfig } = useMonsterConfigFetcher({});
  return monsterverseConfig?.type === "student" ? monsterverseConfig.parentConsentStatus : undefined;
};

export const useMonsterverseConfiguration = (classId?: string) => {
  const { data: monsterverseConfig } = useMonsterConfigFetcher({ classId });
  if (monsterverseConfig?.type === "student") {
    return monsterverseConfig.config;
  }
};
