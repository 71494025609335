import { Fragment, Suspense } from "react";
import { Outlet } from "react-router-dom";
import type { ActionFunction, RouteObject, LoaderFunction } from "react-router-dom";
import { generatePreservedRoutes, generateRegularRoutes } from "@generouted/react-router/core";
import { ReactRouterErrorBoundary } from "@classdojo/web";
import * as ModuleError from "./ModuleError";
import NotFound from "@classdojo/web/components/errors/NotFound";
import { captureException } from "@web-monorepo/telemetry";

type Element = () => JSX.Element;
type Module = {
  default: Element;
  Loader?: LoaderFunction;
  Action?: ActionFunction;
  Catch?: Element;
  Pending?: Element;
};

const PRESERVED = import.meta.glob<Module>("/src/pages/(_app|404).tsx", { eager: true });
const ROUTES = import.meta.glob<Module>(["/src/pages/**/[\\w[-]*.{jsx,tsx}", "!/**/(_app|404).*"]);

const preservedRoutes = generatePreservedRoutes<Omit<Module, "Action">>(PRESERVED);

const regularRoutes = generateRegularRoutes<RouteObject, () => Promise<Partial<Module>>>(ROUTES, (module, key) => {
  const index = /index\.(jsx|tsx)$/.test(key) && !key.includes("pages/index") ? { index: true } : {};

  return {
    ...index,
    lazy: async () => {
      const realizedModule = await module().catch((x) => {
        captureException(x);
        return ModuleError as Module;
      });
      const Element = realizedModule?.default || Fragment;
      const Pending = realizedModule?.Pending;
      const Page = () =>
        Pending ? (
          <Suspense fallback={<Pending />}>
            <Element />
          </Suspense>
        ) : (
          <Element />
        );

      return {
        Component: Page,
        ErrorBoundary: realizedModule?.Catch,
        loader: realizedModule?.Loader,
        action: realizedModule?.Action,
      };
    },
  };
});

const _app = preservedRoutes?._app;
// This pattern should come into play once we are done 404s falling through to LazyManualRoutes:
// const _404 = preservedRoutes?.["404"];

const Element = _app?.default || Fragment;
const App = () =>
  _app?.Pending ? (
    <Suspense fallback={<_app.Pending />}>
      <Element />
    </Suspense>
  ) : (
    <Element />
  );

const app = { Component: _app?.default ? App : Outlet, ErrorBoundary: _app?.Catch, loader: _app?.Loader };
const fallback: RouteObject = {
  path: "*",
  element: <NotFound />,
};

export const routes: RouteObject[] = [
  { ...app, errorElement: <ReactRouterErrorBoundary />, children: [...regularRoutes, fallback] },
];
