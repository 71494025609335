import combineActionHandlers from "@web-monorepo/infra/combineActionHandlers";
import type { Reducer } from "redux";
import type { Saga } from "redux-saga";
import { isTesting, isClient } from "../../utils/env";
import {
  requestPermission,
  requestPermissionSaga,
  webcamRequestPermissionHandler,
  webcamPermissionAllowedHandler,
  webcamPermissionDeniedHandler,
  webcamNoCameraHandler,
  webcamWaitingOnUserHandler,
  WEBCAM_INIT_LAG,
} from "./data/requestPermission";

if (!isTesting && isClient) {
  import("webrtc-adapter/out/adapter");
}

export { requestPermission, WEBCAM_INIT_LAG };

// amount of time we'll wait for browser to turn on webcam before assuming
// that we're waiting on the user to give us permissions.
const STATE_KEY = "webcamPermissions";

export type WebcamPermissionsState = {
  hasCamera: boolean | null;
  hasPermission: boolean | null;
  isRequestingPermission: boolean;
  isWaitingOnUser: boolean;
};

export type WebcamPermissionsSlice = {
  [STATE_KEY]: WebcamPermissionsState;
};

const initialState: WebcamPermissionsState = {
  hasCamera: null, // null = unknown
  hasPermission: null, // null = unknown
  isRequestingPermission: false,
  isWaitingOnUser: false,
};

export const selectState = (state: WebcamPermissionsSlice): WebcamPermissionsState => state[STATE_KEY];

const reducer = combineActionHandlers(initialState, [
  webcamRequestPermissionHandler,
  webcamPermissionAllowedHandler,
  webcamPermissionDeniedHandler,
  webcamNoCameraHandler,
  webcamWaitingOnUserHandler,
]);

export default function installer(
  installReducer: (stateKey: string, reducer: Reducer) => void,
  installSaga: (saga: Saga) => void,
): void {
  installReducer(STATE_KEY, reducer);
  installSaga(requestPermissionSaga);
}
