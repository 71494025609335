import * as logClient from "@classdojo/log-client";

declare global {
  interface Window {
    webkit?: {
      messageHandlers?: {
        iOSMessageHandler?: {
          postMessage: (messageBody: { action: string } & Record<string, unknown>) => void;
        };
      };
    };
    addEventListener<K extends keyof CustomEventMap>(
      type: K,
      listener: (this: Document, ev: CustomEventMap[K]) => void,
    ): void;
    removeEventListener<K extends keyof CustomEventMap>(
      type: K,
      listener: (this: Document, ev: CustomEventMap[K]) => void,
    ): void;
    dispatchEvent<K extends keyof CustomEventMap>(ev: CustomEventMap[K]): void;
  }

  interface CustomEventMap {
    callHandlerTimeout: CustomEvent<{ timeout: number }>;
  }
}

const WEB_START_TIME = Date.now();
const CALL_HANDLER_TIMEOUT = 10 * 1000; // milliseconds

type HostMessage = { action: string; data?: any; callbackId?: string };
type HostMessageCallback = (data?: Record<string, unknown>) => void;
type HostMessageHandler = {
  postMessage: (messageBody: { action: string } & Record<string, unknown>, targetOrigin?: string) => void;
};

let availableMethods: string[] = ["app::platform::handshake", "app::exit"];

const callbackRegistry: Record<string, HostMessageCallback> = {};
let actionRegistry: Record<string, HostMessageCallback> = {};

let lastCallbackId = 0;

// holds a list of messages to send to the host once it is connected
let messageQueue: Array<HostMessage> = [];
let isConnected = false;

export const getHostHandler = (): HostMessageHandler | undefined =>
  window.webkit?.messageHandlers?.iOSMessageHandler || (window !== window?.parent ? window?.parent : undefined);

const registerCallback = (callback: HostMessageCallback) => {
  const callbackId = `cb${lastCallbackId++}`;
  callbackRegistry[callbackId] = callback;
  return callbackId;
};

const executeCallback = (callbackId: string, data: any) => {
  if (callbackRegistry[callbackId]) {
    callbackRegistry[callbackId](data);
    delete callbackRegistry[callbackId];
  }
};
const executeAction = (action: string, data: any) => {
  if (actionRegistry[action]) {
    actionRegistry[action](data);
  }
};

const methodAvailable = (methodName: string): boolean => availableMethods.includes(methodName);

const postMessageToHost = (message: HostMessage) => {
  if (!methodAvailable(message.action)) {
    logClient.logException(new Error("[HomeIslands Map] Method not available in webview iframe bridge callhander"), {
      message,
    });
  }
  const handler = getHostHandler();
  handler?.postMessage(message, "*");
};

const callHostHandler = (action: string, data?: any, callback?: HostMessageCallback, forceSend = false) => {
  const callbackId = callback ? registerCallback(callback) : undefined;
  const message = { action, data, callbackId };
  if (isConnected || forceSend) {
    postMessageToHost(message);
  } else {
    messageQueue.push(message);
  }
};

export function forceCloseWebView(context?: Record<string, unknown>) {
  logClient.logMessage("warn", "[HOME_ISLANDS] Force closing island map web view...", context);
  callHostHandler("app::exit", undefined, undefined, true);
}

export function createWebViewIframeBridge() {
  const iframeBridge = {
    initBridge() {
      // Listen for messages
      window.addEventListener("message", function (event) {
        if ("callbackId" in event.data) {
          const { callbackId, data } = event.data;
          executeCallback(callbackId, data);
        } else {
          if ("action" in event.data) {
            const { action, data } = event.data;
            executeAction(action, data);
          }
        }
      });

      return new Promise<void>((resolve, reject) => {
        const queueIntervalId = setInterval(() => {
          if (Date.now() - WEB_START_TIME > CALL_HANDLER_TIMEOUT) {
            clearInterval(queueIntervalId);
            window.dispatchEvent(
              new CustomEvent("callHandlerTimeout", {
                detail: { timeout: CALL_HANDLER_TIMEOUT },
              }),
            );
            // eslint-disable-next-line prefer-promise-reject-errors
            reject();
            return;
          }

          const handleHanshakeCb = (data: { availableMethods?: string[] }) => {
            isConnected = true;
            availableMethods = data.availableMethods || [];
            clearInterval(queueIntervalId);
            messageQueue.forEach((msg) => postMessageToHost(msg));
            messageQueue = [];
            resolve();
          };

          callHostHandler("app::platform::handshake", undefined, handleHanshakeCb, true);
        }, 500);
      });
    },
    initialLoadingComplete() {
      callHostHandler("app::platform::initialLoadingComplete");
    },
    navigateToDojoIslands(dojoIslandsType: string, islandId: string) {
      callHostHandler("app::navigateToDojoIslands", { dojoIslandsType, islandId });
    },
    canShowMonsterCustomizer() {
      return methodAvailable("app::navigateToCustomizer");
    },
    navigateToCustomizer() {
      callHostHandler("app::navigateToCustomizer");
    },
    canShowSparks() {
      return methodAvailable("app::navigateToSparks");
    },
    navigateToSparks() {
      callHostHandler("app::navigateToSparks");
    },
    exit() {
      actionRegistry = {};
      callHostHandler("app::exit");
    },
    registerActionHandler(action: string, cb: HostMessageCallback) {
      actionRegistry[action] = cb;
    },
  };

  return iframeBridge;
}
