import { getLocalTokenData } from "app/pods/tokenLogin";
import { getItem } from "app/utils/docCookies";

// High level context: students need to be "activated" or "verified" or whatever by their parents to
// unlock most features. This is the key difference between parent (home) QR codes and teacher (classroom) QR codes
// if a student signs up via a parent's QR, they are automatically activated permanently
// however b/c of legal stuff or whatever, teachers can't give permission to students on a permanent basis.
// students can use the app in the classroom w/ permission of teacher, but not forever, wherever
// so, when logging in or signing up via a teacher QR code, we drop that permission token into local storage
// (this is not a good system by the way, just FYI)
// then on the backend, we accept story posts that EITHER are from students that have been activated by a parent
// OR that carry this permission token along so, ultimately the presence of the permission token
// should indicate the user signed in with a teacher's QR code

export default function isAutoCreatedUser() {
  return Boolean(getItem("permissionToken"));
}

// Logged in via class qr code vs parent qr code:
// This is not the most elegant way to find out whether the log in was made through the parent acc or not,
// the main issue is that we are 'deducing' such information as a side effect of how we decided to store
// certain data (with a token in the local storage), this could change in the future and break this functionality.
// TODO: Find a better way or more explicit way in the future for 'tagging' a login as class qr vs parent qr.
// We should probably create a flag in our reducer to specifically say 'loggedInVia', rather than deducing from the
// token present in the local storage. (however, this should do for now)

export function loggedInViaClassQRCode() {
  const token = getLocalTokenData() || {};
  return !!token.classId;
}

export function loggedInViaParentQRCode() {
  return isAutoCreatedUser() && !loggedInViaClassQRCode();
}
