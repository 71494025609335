import { createUploadPayloadFromBase64ImageData, isValidUploadPayload } from "@classdojo/web/pods/s3Upload/media";
import { fetchWithoutCredentials } from "@web-monorepo/infra/callApi";
import callApi from "@web-monorepo/infra/callApi";

const getUploadURL = async (type: string, filename: null | string) => {
  const updatedFilename = filename === null ? "" : encodeURIComponent(filename);

  const response = await callApi({
    path: "/api/uploadFileURL",
    query: updatedFilename ? { type, filename: updatedFilename } : { type },
  });

  return {
    uploadUrl: response.body._links.url.href,
    destinationUrl: response.body.path,
  };
};

const assertValidUploadPayload = (uploadPayload: PhotoMediaType): PhotoMediaType => {
  if (!isValidUploadPayload(uploadPayload)) {
    throw new Error(`Invalid media upload payload: keys are ${Object.keys(uploadPayload)}`);
  }
  return uploadPayload;
};

const uploadFile = async (uploadPayload: PhotoMediaType) => {
  const { id, data, attachmentType, contentType, filename } = assertValidUploadPayload(uploadPayload);
  const { uploadUrl, destinationUrl } = await getUploadURL(attachmentType, filename);

  // Using `fetchWithoutCredentials` instead of `callApi` because we don't want to
  // treat this request as JSON, and also don't want to include our auth
  // token. However, we do want to track this request for testing
  await fetchWithoutCredentials({
    method: "PUT",
    path: uploadUrl,
    body: data,
    headers: {
      "Content-Type": contentType,
    },
  });

  return { id, path: destinationUrl };
};

export type PhotoMediaType = ReturnType<typeof createUploadPayloadFromBase64ImageData> & {
  width?: number;
  height?: number;
};

interface PhotoAttachment {
  type: "photo";
  path: string;
  dimensions?: { width: number; height: number };
  contentType: string;
}

export const uploadPostAttachment = async ({ media }: { media: PhotoMediaType }): Promise<PhotoAttachment> => {
  const { path } = await uploadFile(media);

  const attachment = {
    type: media.attachmentType,
    path,
    ...(media.width && media.height ? { dimensions: { width: media.width, height: media.height } } : {}),
    contentType: media.contentType,
  };

  return attachment;
};
