import ReactDOM from "react-dom/client";
import App from "./index";

/* eslint-disable @web-monorepo/no-css-file-imports */
import "@classdojo/web/pods/font/dojoText.css";
import "../app/assets/stylesheets/base.css";
import "../app/assets/stylesheets/fonts/drawingFonts.css";
import "@web-monorepo/dojo-islands/fonts/grandstander.css";
import "@web-monorepo/dojo-islands/fonts/omoType.css";
/* eslint-enable @web-monorepo/no-css-file-imports */

const root = document.getElementById("root");
if (!root) throw new Error("couldn't find #root element");

ReactDOM.createRoot(root).render(<App />);
