import { I18nextContainer, initialize } from "@web-monorepo/i18next";
import { LoadingMojo } from "@classdojo/web";

const I18N_NAMESPACES = ["dojo.common", "dojo.student_web"];

interface I18NContainerStudentProps {
  locale: string | null;
  children: JSX.Element;
}

initialize({ ns: I18N_NAMESPACES });

const I18NContainerStudent = ({ locale, children }: I18NContainerStudentProps) => {
  return (
    <I18nextContainer locale={locale} namespaces={I18N_NAMESPACES} fallback={<LoadingMojo noOverlay border />}>
      {children}
    </I18nextContainer>
  );
};

export default I18NContainerStudent;
