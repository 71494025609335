import { errorTypeMaker } from "@web-monorepo/shared/errors/errorTypeMaker";

const errors = {
  api: {
    auth: errorTypeMaker("api-auth"),
    down: errorTypeMaker("api-down"),
    unknown: errorTypeMaker("api-unknown"),
    rateLimit: errorTypeMaker("api-rateLimit"),

    // note: not for all 403s and 404s, just for ones that should redirect you to a special page.
    forbidden: errorTypeMaker("api-forbidden"),
    notFound: errorTypeMaker("api-notFound"),
  },
  login: {
    incorrectUserType: errorTypeMaker("login-incorrectUserType"),
    lockout: errorTypeMaker("login-lockout"),
    invalid: errorTypeMaker("login-invalid"),
    mustUseOtc: errorTypeMaker("login-mustUseOtc"),
  },
  invitation: {
    taken: errorTypeMaker<{ username: string }>("invitation-taken"),
    notFound: errorTypeMaker("invitation-notFound"),
    invalidCodeType: errorTypeMaker("invitation-invalidCodeType"),
    doubleClassCodeUse: errorTypeMaker<{ classroom: string }>("invitation-doubleClassCodeUse"),
  },
  forgotPassword: {
    usernameNotFound: errorTypeMaker("forgotPassword-usernameNotFound"),
    parentNotConfirmed: errorTypeMaker("forgotPassword-parentNotConfirmed"),
  },
  student: {
    missingConsent: errorTypeMaker("student-missingConsent"),
  },
} as const;

export default errors;
