// Generouted, changes to this file will be overriden
/* eslint-disable */

import { hooks, utils } from "@generouted/react-router/client";
import { components } from "@web-monorepo/routing";

export type Path =
  | `/`
  | `/assignments`
  | `/assignments/:assignmentId`
  | `/assisted-login`
  | `/automatic-translation-test/route`
  | `/avatar`
  | `/classes`
  | `/classroomCheck`
  | `/dashboard`
  | `/data-export`
  | `/directLogin`
  | `/dojoIslands`
  | `/drawing`
  | `/find-code`
  | `/forgot`
  | `/get-app`
  | `/home`
  | `/home/islands`
  | `/home/islands/:islandId`
  | `/home/islands/:islandId/edit`
  | `/home/islands/:islandId/leave`
  | `/home/islands/add`
  | `/home/islands/locked`
  | `/journal`
  | `/login`
  | `/loginGoogle`
  | `/loginUsername`
  | `/logout`
  | `/photo`
  | `/play`
  | `/playError`
  | `/playExit`
  | `/playV2`
  | `/portfolio/classes/:classroomId`
  | `/portfolio/folders/:folderId`
  | `/portfolio/items/:itemId`
  | `/portfolioV2`
  | `/qr-reader`
  | `/qrCode`
  | `/qrReader`
  | `/register`
  | `/reports`
  | `/reports/:id`
  | `/signin`
  | `/signup`
  | `/signup/:code`
  | `/story`
  | `/studentOAuth2Callback`
  | `/studentOAuth2Success`
  | `/textCode`
  | `/tokenLogin`
  | `/tokenLoginV2`
  | `/triggerErrorOnClick`
  | `/triggerErrorOnRender`
  | `/unlock-class`
  | `/userPassLogin`
  | `/video`;

export type Params = {
  "/assignments/:assignmentId": { assignmentId: string };
  "/home/islands/:islandId": { islandId: string };
  "/home/islands/:islandId/edit": { islandId: string };
  "/home/islands/:islandId/leave": { islandId: string };
  "/portfolio/classes/:classroomId": { classroomId: string };
  "/portfolio/folders/:folderId": { folderId: string };
  "/portfolio/items/:itemId": { itemId: string };
  "/reports/:id": { id: string };
  "/signup/:code": { code: string };
};

export type ModalPath = never;

export const { Link, Navigate } = components<Path, Params>();
export const { useModals, useNavigate, useParams } = hooks<Path, Params, ModalPath>();
export const { redirect } = utils<Path, Params>();
