import { CollectionFetcherReturnType } from "@web-monorepo/shared/api/apiTypesHelper";
import { makeCollectionQuery } from "@web-monorepo/shared/reactQuery";
import find from "lodash/find";
import { useMemo } from "react";

const ITEMS_PER_PAGE = "100";

export type Assignment = CollectionFetcherReturnType<typeof useAssignmentsFetcher>;

export const useAssignmentsFetcher = makeCollectionQuery({
  fetcherName: "assignments",
  path: "/api/portfolioAssignment",
  query: { limit: ITEMS_PER_PAGE, completed: "false", includeMultipageWorksheets: "true" },
  dontThrowOnStatusCodes: [403],
});

// @fetcher-revisit: not really a fetcher, but keeping same interface
// so is it ok to call it fetcher?
export const useAssignmentFetcher = ({ assignmentId }: { assignmentId: string }) => {
  const { data, ...other } = useAssignmentsFetcher({});

  const assignment = useMemo(() => find(data, { _id: assignmentId }), [assignmentId, data]);

  return { data: assignment, ...other };
};
