import { Buffer as BufferPolyfill } from "buffer";
import uniqueId from "lodash/uniqueId";

const Buffer = global.Buffer || BufferPolyfill;

export function createUploadPayloadFromBase64ImageData(base64ImageData: string) {
  const { data, contentType } = base64ImageDataToBinary(base64ImageData);
  return {
    data,
    preview: base64ImageData,
    filename: null,
    attachmentType: "photo" as const,
    contentType,
    id: uniqueId(""),
  };
}

export const isValidUploadPayload = (payload: Record<string, unknown>) =>
  ["id", "data", "attachmentType", "contentType"].every((k) => !!payload[k]);

const JPEG_PREFIX = "data:image/jpeg;base64,";
const PNG_PREFIX = "data:image/png;base64,";

function base64ImageDataToBinary(base64Data: string) {
  switch (true) {
    case base64Data.startsWith(JPEG_PREFIX):
      return { contentType: "image/jpeg", data: Buffer.from(base64Data.substring(JPEG_PREFIX.length), "base64") };
    case base64Data.startsWith(PNG_PREFIX):
      return { contentType: "image/png", data: Buffer.from(base64Data.substring(PNG_PREFIX.length), "base64") };
    default:
      throw new Error("Can only convert base64 png or jpeg data");
  }
}
