import createActionHandler from "@web-monorepo/infra/createActionHandler";
import invokeMap from "lodash/invokeMap";
import type { AnyAction } from "redux";
import type { SagaIterator } from "redux-saga";
import { fork, join, race, call, put, delay } from "redux-saga/effects";
import { takeLatest } from "redux-saga/effects";
import { WebcamPermissionsState } from "..";

export const REQUEST_PERMISSION = "web/webcamPermissions/requestPermission";
export const PERMISSION_ALLOWED = "web/webcamPermissions/permissionAllowed";
export const PERMISSION_DENIED = "web/webcamPermissions/permissionDenied";
export const NO_CAMERA = "web/webcamPermissions/noCamera";
export const WAITING_ON_USER = "web/webcamPermissions/waitingOnUser";
export const WEBCAM_INIT_LAG = 1000;

export type WebcamPermissions = {
  audio?: boolean;
  video?: boolean;
};

type RequestPermissionActionType = {
  type: typeof REQUEST_PERMISSION;
  payload: WebcamPermissions;
};
export const requestPermission = ({
  video = true,
  audio = false,
}: WebcamPermissions = {}): RequestPermissionActionType => ({
  type: REQUEST_PERMISSION,
  payload: { video, audio },
});

export function* requestPermissionFromBrowserSaga(action: AnyAction): SagaIterator {
  try {
    // call() with an array param is bound function call
    const mediaStream: MediaStream = yield call(
      [navigator.mediaDevices, navigator.mediaDevices.getUserMedia],
      action.payload,
    );

    invokeMap(mediaStream.getVideoTracks(), "stop");

    yield put({
      type: PERMISSION_ALLOWED,
    });
    // eslint-disable-next-line no-catch-all/no-catch-all
  } catch (e: any) {
    // TODO: handle more errors
    if (e.name === "NotAllowedError") {
      yield put({ type: PERMISSION_DENIED });
      return;
    }

    yield put({ type: NO_CAMERA });
  }
}

export function* askUserForPermissionsSaga(action: AnyAction): SagaIterator {
  // fork requesting permissions because we don't want the race below cancel
  // the permissions saga if the delay wins.
  const requestPermissionsTask = yield fork(requestPermissionFromBrowserSaga, action);

  // if the user has already given us permissions to their webcam, it still
  // takes some time for the browser to turn on the webcam and tell us it was
  // successful.
  const { awaitWebcamLag } = yield race({
    requestPermissions: join(requestPermissionsTask),
    awaitWebcamLag: delay(WEBCAM_INIT_LAG),
  });

  if (awaitWebcamLag) {
    // delay won the race, meaning we're waiting on user to allow/deny
    yield put({
      type: WAITING_ON_USER,
    });
  }
}

export function* requestPermissionSaga(): SagaIterator {
  yield takeLatest(REQUEST_PERMISSION, askUserForPermissionsSaga);
}

export const webcamRequestPermissionHandler = createActionHandler(
  REQUEST_PERMISSION,
  () => (o: WebcamPermissionsState) => ({ ...o, isRequestingPermission: true }),
);

export const webcamPermissionAllowedHandler = createActionHandler(PERMISSION_ALLOWED, () => {
  return (o: WebcamPermissionsState) => {
    return {
      ...o,
      hasCamera: true,
      hasPermission: true,
      isRequestingPermission: false,
      isWaitingOnUser: false,
    };
  };
});

export const webcamPermissionDeniedHandler = createActionHandler(PERMISSION_DENIED, () => {
  return (o: WebcamPermissionsState) => {
    return {
      ...o,
      hasCamera: true,
      hasPermission: false,
      isRequestingPermission: false,
      isWaitingOnUser: false,
    };
  };
});

export const webcamNoCameraHandler = createActionHandler(NO_CAMERA, () => {
  return (o: WebcamPermissionsState) => {
    return {
      ...o,
      hasCamera: false,
      isRequestingPermission: false,
      isWaitingOnUser: false,
    };
  };
});

export const webcamWaitingOnUserHandler = createActionHandler(WAITING_ON_USER, () => (o: WebcamPermissionsState) => ({
  ...o,
  isWaitingOnUser: true,
}));
