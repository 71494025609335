import * as logClient from "@classdojo/log-client";
import useWatch from "@classdojo/web/hooks/useWatch";
import ToastBanner from "@classdojo/web/nessie/components/ToastBanner";
import { createUploadPayloadFromBase64ImageData } from "@classdojo/web/pods/s3Upload/media";
import { UploadedFileInfo, uploadViaUppy, VideoFileInfo } from "@classdojo/web/utils/uppy";
import { useInterval } from "@web-monorepo/hooks";
import callApi from "@web-monorepo/infra/callApi";
import combineActionHandlers from "@web-monorepo/infra/combineActionHandlers";
import createActionHandler from "@web-monorepo/infra/createActionHandler";
import { PodInstallFunction } from "@web-monorepo/shared/podInfra";
import { makeCollectionQuery, makeMutation } from "@web-monorepo/shared/reactQuery";
import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";
import { useAssignmentsFetcher } from "app/pods/activities";
import { DrawingData, EventLoggingParams } from "app/pods/activities/types";
import { PortfolioAttachment } from "app/pods/drafts/types";
import { formatStoryAttachmentForPortfolio, StoryAttachment, usePortfolioItemsFetcher } from "app/pods/portfolio";
import { RenderedComment, RenderedPortfolioItem } from "app/pods/portfolio/types";
import { uploadPostAttachment } from "app/pods/postMedia";
import { loggedInViaClassQRCode, loggedInViaParentQRCode } from "app/utils/isAutoCreatedUser";
import maxBy from "lodash/maxBy";
import MobileDetect from "mobile-detect";
import { useCallback, useRef } from "react";
import { toast } from "react-toastify";
import { RenderedStoryPostObject } from "./types";

declare global {
  interface Window {
    Cypress?: {
      _onlyForCypressPushStoryPostReads: boolean;
      navigateTo?: (url: string | URL) => void;
      sounds?: { play: (soundName: string) => any };
    };
  }
}

const STATE_KEY = "story";

type State = {
  currentTargetId?: string | null;
  initialTargetSet: boolean;
};

const initialState: State = {
  currentTargetId: null,
  // controls if initial selected classroom was already set or not,
  // since we only want to do it once at the start of the app
  initialTargetSet: false,
};

const SET_CURRENT_TARGET = "story/setCurrentTarget";
const SET_INITIAL_TARGET = "story/setInitialTarget";

export const setCurrentTarget = (targetId: string) => ({
  type: SET_CURRENT_TARGET,
  payload: { targetId },
});

export const setInitialTarget = (targetId: string) => ({
  type: SET_INITIAL_TARGET,
  payload: { targetId },
});

const finalReducer = combineActionHandlers(initialState, [
  createActionHandler(
    SET_CURRENT_TARGET,
    ({ targetId }: { targetId: string }, state: State): State => ({
      ...state,
      currentTargetId: targetId,
    }),
  ),
  createActionHandler(SET_INITIAL_TARGET, ({ targetId }: { targetId: string }, state: State): State => {
    if (!state.initialTargetSet) {
      return { ...state, currentTargetId: targetId, initialTargetSet: true };
    }
    return state;
  }),
]);

export const selectCurrentTarget = (state: Record<string, typeof initialState>) => state[STATE_KEY].currentTargetId;

const install: PodInstallFunction = (installReducer) => {
  installReducer(STATE_KEY, finalReducer);
};

export default install;

export const useClassStoryPostsFetcher = makeCollectionQuery({
  fetcherName: "classStoryPosts",
  path: "/api/storyFeed",
  query: { pending: "true", portfolio: "false" },
  queryParams: ["classId", "withStudentCommentsAndLikes"],
});

export const useStoryPostCommentsFetcher = makeCollectionQuery({
  fetcherName: "postComments",
  path: "/api/dojoClass/{classId}/storyPost/{postId}/comments",
  queryParams: ["withStudentCommentsAndLikes"],
  onSuccess: (data, params) => {
    useClassStoryPostsFetcher.setQueriesData(
      (draft) => {
        const post = draft.find(({ _id }) => _id === params.postId);
        if (post) {
          const comments = data;
          post.commentCount = comments.length;
        }
      },
      { classId: params.classId },
    );
  },
});

function makeStoryPostPath(targetType: string, targetId: string, postId: string, extra: string[]) {
  let base;
  if (targetType === "class") {
    base = ["dojoClass", targetId, "storyPost"];
  } else if (targetType === "school") {
    base = ["dojoSchool", targetId, "storyFeed"];
  } else {
    throw new Error(`Invalid story post type: ${targetType}`);
  }
  return ["/api"]
    .concat(base)
    .concat(postId ? [postId] : [])
    .concat(extra)
    .filter(Boolean)
    .join("/");
}

interface CreateStoryPostCommentOperationParams {
  targetType: string;
  targetId: string;
  postId: string;
  body: string;
  withCommentsAndLikes: boolean;
}

export const useCreateStoryPostCommentOperation = makeMutation<
  CreateStoryPostCommentOperationParams,
  { status: number; body: RenderedComment }
>({
  name: "createStoryPostComment",
  fn: async ({ targetType, targetId, postId, body, withCommentsAndLikes }) => {
    return await callApi({
      method: "POST",
      path: makeStoryPostPath(targetType, targetId, postId, ["comments"]),
      body: { body },
      query: { withStudentCommentsAndLikes: withCommentsAndLikes },
    });
  },
  onMutate: (params) => {
    useClassStoryPostsFetcher.setQueriesData(
      (draft) => {
        const post = draft.find(({ _id }) => _id === params.postId);
        if (post) {
          post.commentCount = post.commentCount + 1;
        }
      },
      { classId: params.targetId },
    );
  },
  onSuccess: (data, params) => {
    useStoryPostCommentsFetcher.setQueriesData(
      (draft) => {
        draft?.push(data.body);
      },
      { postId: params.postId, classId: params.targetId },
    );
  },
});

interface DeleteStoryPostCommentOperationParams {
  targetType: string;
  targetId: string;
  postId: string;
  commentId: string;
}

export const useDeleteStoryPostCommentOperation = makeMutation<DeleteStoryPostCommentOperationParams, void>({
  name: "deleteStoryPostComment",
  fn: async ({ targetType, targetId, postId, commentId }) => {
    await callApi({
      method: "DELETE",
      path: makeStoryPostPath(targetType, targetId, postId, ["comments", commentId]),
    });
  },
  onMutate: (params) => {
    useStoryPostCommentsFetcher.setQueriesData(
      (draft) => {
        return draft?.filter((comment) => comment._id !== params.commentId);
      },
      { postId: params.postId, classId: params.targetId },
    );
    useClassStoryPostsFetcher.setQueriesData(
      (draft) => {
        const post = draft.find(({ _id }) => _id === params.postId);
        if (post) {
          post.commentCount = post.commentCount - 1;
        }
      },
      { classId: params.targetId },
    );
  },
});

export const useStoryPostLikesFetcher = makeCollectionQuery({
  path: "/api/dojoClass/{classId}/storyFeed/{postId}/likes",
  queryParams: ["withStudentCommentsAndLikes"],
  fetcherName: "storyPostLikes",
  onSuccess: (data, params) => {
    useClassStoryPostsFetcher.setQueriesData(
      (draft) => {
        const post = draft.find(({ _id }) => _id === params.postId);
        if (post) {
          const likes = data;
          post.likeCount = likes.length;
        }
      },
      { classId: params.classId },
    );
  },
});

interface LikeAndUnlikeStoryPostOperationParams {
  targetType: string;
  targetId: string;
  postId: string;
  withCommentsAndLikes: boolean;
}

export const useLikeStoryPostOperation = makeMutation<LikeAndUnlikeStoryPostOperationParams, void>({
  name: "likeStoryPost",
  fn: async ({ targetType, targetId, postId, withCommentsAndLikes }) => {
    await callApi({
      method: "POST",
      path: "/api/storyBatchAction",
      body: {
        actions: [{ action: "like", targetType, targetId, postId }],
      },
      query: { withStudentCommentsAndLikes: withCommentsAndLikes },
    });
  },
  onMutate: (params) => {
    useClassStoryPostsFetcher.setQueriesData(
      (draft) => {
        const post = draft.find(({ _id }) => _id === params.postId);
        if (post) {
          post.likeCount = post.likeCount + 1;
          post.likeButton = "liked";
        }
      },
      { classId: params.targetId },
    );
  },
  onSuccess: (_data, params) => {
    useStoryPostLikesFetcher.invalidateQueries({ postId: params.postId, classId: params.targetId });
  },
});

export const useUnlikeStoryPostOperation = makeMutation<LikeAndUnlikeStoryPostOperationParams, void>({
  name: "unlikeStoryPost",
  fn: async ({ targetType, targetId, postId, withCommentsAndLikes }) => {
    await callApi({
      method: "POST",
      path: "/api/storyBatchAction",
      body: {
        actions: [{ action: "unlike", targetType, targetId, postId }],
      },
      query: { withStudentCommentsAndLikes: withCommentsAndLikes },
    });
  },
  onMutate: (params) => {
    useClassStoryPostsFetcher.setQueriesData(
      (draft) => {
        const post = draft.find(({ _id }) => _id === params.postId);
        if (post) {
          post.likeCount = post.likeCount - 1;
          post.likeButton = "unliked";
        }
      },
      { classId: params.targetId },
    );
  },
  onSuccess: (_data, params) => {
    useStoryPostLikesFetcher.invalidateQueries({ postId: params.postId, classId: params.targetId });
  },
});

export function getCreatePostEventLoggingPayload(
  post: RenderedPortfolioItem & { senderId?: string },
  eventLoggingParams: EventLoggingParams,
) {
  const userAgent = window.navigator && window.navigator.userAgent;
  let formFactor;

  const mobileDetect = new MobileDetect(userAgent);
  // This is not ideal or 100% precise, however, should be fine for measuring the majority of our requests.
  // If we find any irregularity we can change the mobile detect library or find a more precise method.
  const isComputer = !mobileDetect.mobile();
  if (isComputer) formFactor = "computer";
  if (mobileDetect.tablet()) formFactor = "tablet";
  if (mobileDetect.phone()) formFactor = "phone";

  let loggedInVia = "username";
  if (loggedInViaClassQRCode()) {
    loggedInVia = "class";
  } else if (loggedInViaParentQRCode()) {
    loggedInVia = "home";
  }

  // We want to make sure we always send all the keys to the event, so we identify if we have inconsistencies
  // depending on the type of post or if we forgot to handle a case (not the most robust but better than having
  // keys being added conditionally).
  const defaultsEventLoggingPayload = {
    postId: null,
    posterId: null,
    formFactor: null,
    isTextJournal: null,
    hasDrawing: null,
    hasFile: null,
    hasPhoto: null,
    hasVoiceNotes: null,
    hasVideo: null,
    loggedInVia: null,
    platform: null,
    hasDrawingLabels: null,
    hasCaption: null,
    contentDuration: null,
  };

  return {
    ...defaultsEventLoggingPayload,
    ...eventLoggingParams,
    postId: post._id,
    posterId: post.senderId,
    loggedInVia,
    platform: "web",
    formFactor,
  };
}

interface CreatePostParams {
  classroomId: string;
  assignmentId?: string;
  body: string;
  attachments: PortfolioAttachment[];
  eventLoggingParams: EventLoggingParams;
}

const createPost = async ({ classroomId, assignmentId, body, attachments, eventLoggingParams }: CreatePostParams) => {
  const response = await callApi({
    path: `/api/dojoClass/${classroomId}/portfolio`,
    method: "POST",
    body: {
      body,
      attachments: attachments || [],
      assignmentId,
    },
  });

  const post: RenderedPortfolioItem = response.body;
  const eventLoggingPayload = getCreatePostEventLoggingPayload(post, eventLoggingParams);
  logClient.logEvent({
    eventName: "student_create_post",
    metadata: eventLoggingPayload,
  });

  return response;
};

export const useCreateJournalPostOperation = makeMutation<CreatePostParams, Await<ReturnType<typeof createPost>>>({
  name: "createJournalPost",
  fn: async ({ classroomId, assignmentId, body, attachments, eventLoggingParams }) => {
    return createPost({ classroomId, assignmentId, body, attachments, eventLoggingParams });
  },
  onSuccess: (_data, params) => {
    useAssignmentsFetcher.invalidateQueries();
    usePortfolioItemsFetcher.invalidateQueries();
    useClassStoryPostsFetcher.invalidateQueries({ classId: params.classroomId });
  },
});

export interface CreatePhotoPostOperationParams {
  classroomId: string;
  assignmentId?: string;
  caption: string;
  width: number;
  height: number;
  dataUrl: string;
  drawingData?: DrawingData;
  eventLoggingParams: EventLoggingParams;
}

export const useCreatePhotoPostOperation = makeMutation<
  CreatePhotoPostOperationParams,
  Await<ReturnType<typeof createPost>>
>({
  name: "createPhotoPost",
  fn: async ({ classroomId, assignmentId, caption, width, height, dataUrl, drawingData, eventLoggingParams }) => {
    const photoMedia = { ...createUploadPayloadFromBase64ImageData(dataUrl), width, height };

    const attachment = { ...(await uploadPostAttachment({ media: photoMedia })), drawingData };

    const post = {
      classroomId,
      assignmentId,
      body: caption || "",
      attachments: [attachment],
      eventLoggingParams,
    };

    return createPost(post);
  },
  onSuccess: (_data, params) => {
    useAssignmentsFetcher.invalidateQueries();
    usePortfolioItemsFetcher.invalidateQueries();
    useClassStoryPostsFetcher.invalidateQueries({ classId: params.classroomId });
  },
});

export interface CreateVideoPostOperationParams {
  classroomId: string;
  caption: string;
  recording?: VideoFileInfo | null;
  assignmentId?: string;
  eventLoggingParams: EventLoggingParams;
}

type Await<T> = T extends PromiseLike<infer U> ? Await<U> : T;

const uploadedFileInfoToStoryAttachment = (
  attachment: UploadedFileInfo | UploadedFileInfo[] | null,
): StoryAttachment | null => {
  if (!attachment) return null;
  if (Array.isArray(attachment)) return attachment[0];
  return attachment;
};

export const useCreateVideoPostOperation = makeMutation<
  CreateVideoPostOperationParams,
  undefined | Await<ReturnType<typeof createPost>>
>({
  name: "createVideoPost",
  fn: async ({
    recording,
    // mimeType,
    caption,
    classroomId,
    assignmentId,
    eventLoggingParams,
  }) => {
    toast(<ToastBanner text={autoTranslate("Uploading files...")} />);
    const attachment: StoryAttachment | null = uploadedFileInfoToStoryAttachment(
      await uploadViaUppy({
        type: "video",
        video: recording,
      }),
    );

    // if attachment is null at this point, the user closed the media upload dialog
    // before uploading anything. return early so that the API request doesn't fail and cause a crash.
    if (attachment == null) return;

    const post = {
      classroomId,
      assignmentId,
      body: caption || "",
      attachments: [formatStoryAttachmentForPortfolio(attachment)],
      eventLoggingParams,
    };

    return await createPost(post).then((res) => {
      toast(<ToastBanner text={autoTranslate("Files uploaded successfully")} />);
      return res;
    });
  },
  onSuccess: (_data, params) => {
    useAssignmentsFetcher.invalidateQueries();
    usePortfolioItemsFetcher.invalidateQueries();
    useClassStoryPostsFetcher.invalidateQueries({ classId: params.classroomId });
  },
});

type MarkStoryPostsReadOperationParams = {
  targetType: string;
  targetId: string;
  postId: string;
}[];

export const useMarkStoryPostsReadOperation = makeMutation<MarkStoryPostsReadOperationParams, void>({
  name: "markStoryPostsRead",
  fn: async (readReceipts) => {
    await callApi({
      method: "POST",
      path: "/api/storyBatchAction?withStudentCommentsAndLikes=true",
      body: {
        actions: readReceipts.map(({ targetType, targetId, postId }) => ({
          action: "read",
          targetType,
          targetId,
          postId,
        })),
      },
    });
  },
  onMutate: (params) => {
    useClassStoryPostsFetcher.setQueriesData((draft) => {
      const reads = params;
      reads.forEach(({ postId }) => {
        const post = draft.find(({ _id }) => _id === postId);
        if (post) {
          post.read = true;
        }
      });
    });
  },
});

type PostParams = { targetId: string; targetType: string; postId: string };

interface ReadPostsTracker {
  _readPost: (params: PostParams) => void;
}

export const useReadPostsTracker = (posts: RenderedStoryPostObject[]): ReadPostsTracker => {
  // use references so callbacks/effect don't get re-created/re-run
  // when current values change
  const pendingReads = useRef<PostParams[]>([]);
  const initialReadDispatched = useRef(false);

  const _readPost = useCallback(({ targetType, targetId, postId }: PostParams) => {
    pendingReads.current.push({ targetId, targetType, postId });
  }, []);

  const { mutate: markStoryPostsRead } = useMarkStoryPostsReadOperation();

  const _pushReadsToAPI = useCallback(() => {
    if (pendingReads.current.length > 0) {
      markStoryPostsRead(pendingReads.current);
      pendingReads.current = [];
    }
  }, [markStoryPostsRead]);

  useInterval(
    () => {
      _pushReadsToAPI();
    },
    // don't push reads by default if we are running on Cypress to avoid
    // flaky test failures from API calls done after test completes,
    // but before page is cleared
    window.Cypress && window.Cypress._onlyForCypressPushStoryPostReads !== true ? null : 1000,
  );

  useWatch([_readPost, posts], () => {
    // We want to be absolutely sure that we clear the unread post count
    // cached value when we first navigate to class story. So, dispatch a
    // read for our first post regardless of whether it's actually been
    // read. Double reads don't matter anyway.
    if (!initialReadDispatched.current && posts && posts.length > 0) {
      // Assert this as defined because size is checked on line above
      const latestPost: RenderedStoryPostObject = maxBy(posts, (p) => Date.parse(p.time))!;
      _readPost({ targetType: latestPost.targetType, targetId: latestPost.targetId, postId: latestPost._id });
      initialReadDispatched.current = true;
    }
  });

  return { _readPost };
};
