export const STUDENT_FEATURE_SWITCHES = {
  SHOW_SPARKS_ON_ISLANDS_MAP: "iOS_studentFeedSparksBanner",
};

export const SCHOOL_FEATURE_SWITCHES: Record<string, string> = {};

// these feature switches will apply to any user session,
// whether the user is logged in or not
export const SESSION_FEATURE_SWITCHES = {
  // If you need to add a new one, you can follow this example:
  // USE_LOGIN_V2: "LoginV2",
};

export const FEATURE_SWITCHES = {
  ...STUDENT_FEATURE_SWITCHES,
  ...SCHOOL_FEATURE_SWITCHES,
  ...SESSION_FEATURE_SWITCHES,
};
