import { Button, LabelText } from "@classdojo/web/nessie";
import { type ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";

const DOJO_ADMIN_READ_ONLY_COOKIE = "dojo_admin_transfer_read_only=true";
const DOJO_ADMIN_WRITABLE_COOKIE = "dojo_admin_transfer_read_only=false";
const dojoAdminCookies = [DOJO_ADMIN_READ_ONLY_COOKIE, DOJO_ADMIN_WRITABLE_COOKIE];

export function getIsAdminSessionAndIsReadOnlySession() {
  const cookies = new Set(document.cookie?.split("; "));
  const cookie = dojoAdminCookies.find((c) => cookies.has(c));

  if (!cookie) {
    return { isAdminSession: false };
  }

  return {
    isAdminSession: true,
    isReadOnlySession: cookie === DOJO_ADMIN_READ_ONLY_COOKIE,
  };
}

const buttonStyles: ThemeUIStyleObject = {
  backgroundColor: "dt_background_primary",
  height: "23px",
  "&:hover span, &:focus span, &:active span": { color: "dt_content_light" },
  span: {
    color: "dt_content_accent",
    fontSize: "10pt",
  },
};

export const AdminImpersonationBanner = ({
  makeSessionsWritable,
  makeSessionReadOnly,
}: {
  makeSessionsWritable: () => void;
  makeSessionReadOnly: () => void;
}) => {
  const { isAdminSession, isReadOnlySession } = getIsAdminSessionAndIsReadOnlySession();
  return (
    <>
      {isAdminSession && (
        <div
          sx={{
            backgroundColor: isReadOnlySession ? "dt_content_warning" : "dt_content_danger",
            display: "flex",
            padding: "dt_xs",
            position: "relative",
            justifyContent: "center",
            alignItems: "center",
            gap: "dt_s",
          }}
        >
          <LabelText kind="light">
            {isReadOnlySession ? "Read-only admin" : "Admin"} session impersonating this user
          </LabelText>
          {isReadOnlySession ? (
            <Button data-name="admin_session:upgrade_to_edit" onClick={makeSessionsWritable} size="s" sx={buttonStyles}>
              Allow edits
            </Button>
          ) : (
            <Button
              data-name="admin_session:downgrade_to_read_only"
              onClick={makeSessionReadOnly}
              size="s"
              sx={buttonStyles}
            >
              Make read-only
            </Button>
          )}
        </div>
      )}
    </>
  );
};
